<template>
  <div v-if="model">
    <div class="row justify-content-center">
      <div class="form-group col-4">
        <label for>UAN</label>
        <fg-input
          type="text"
          name="UAN"
          placeholder="UAN"
          v-model="model.UAN"
          v-validate="modelValidations.UAN"
          :error="getError('UAN')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>EPF No</label>
        <fg-input
          type="text"
          name="epf_no"
          placeholder="EPF No"
          v-model="model.epf_no"
          v-validate="modelValidations.epf_no"
          :error="getError('epf_no')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>ESIC No</label>
        <fg-input
          type="number"
          name="esic_no"
          placeholder="ESIC No"
          v-model="model.esic_no"
          v-validate="modelValidations.esic_no"
          :error="getError('esic_no')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>ESIC Dispensary Name</label>
        <fg-input
          type="text"
          name="esic_dispensary_name"
          placeholder="ESIC Dispensary Name"
          v-model="model.esic_dispensary_name"
          v-validate="modelValidations.esic_dispensary_name"
          :error="getError('esic_dispensary_name')"
        ></fg-input>
      </div>
      <div class="form-group col-4">
        <label for>ESIC Branch Name</label>
        <fg-input
          type="text"
          name="esic_branch"
          placeholder="ESIC Branch Name"
          v-model="model.esic_branch"
          v-validate="modelValidations.esic_branch"
          :error="getError('esic_branch')"
        ></fg-input>
      </div>
      <div class="form-group col-4">
        <label for>Location</label>
        <fg-input
          type="text"
          name="location_id"
          v-model="model.location_id"
          v-validate="modelValidations.location_id"
          :error="getError('location_id')"
        >
          <el-select
            name="location_id"
            class="select-defaul"
            placeholder="Location"
            v-model="model.location_id"
          >
            <el-option
              v-for="option in locations"
              class="select-success"
              :value="option.id"
              :label="`${option.location_id} - ${option.name}`"
              :key="option.id"
            ></el-option>
          </el-select>
        </fg-input>
      </div>
    </div>
  </div>
</template>
<script>
import { IconCheckbox } from "src/components/UIComponents";
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
export default {
  components: {
    IconCheckbox,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      locations: "getLocations",
      model: "getEmployee"
    })
  },
  mounted() {
    this.$store.dispatch("fetchLocationsAll");
  },
  data() {
    return {
      modelValidations: {
        location_id: {
          required: true
        },
        esic_branch: {
          required: false
        },
        esic_dispensary_name: {
          required: false
        },
        esic_no: {
          required: false
        },
        espf_no: {
          required: false
        },
        UAN: {
          required: false
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        let m = {
          location_id: this.model.location_id,
          esic_branch: this.model.esic_branch,
          esic_dispensary_name: this.model.esic_dispensary_name,
          esic_no: this.model.esic_no,
          epf_no: this.model.epf_no,
          UAN: this.model.UAN
        };
        this.$emit("on-validated", res, m);
        return res;
      });
    }
  }
};
</script>
<style>
</style>
